import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from './page/navigation/footer/footer.component';
import { MainNavComponent } from './page/navigation/main-nav/main-nav.component';
import { environment } from '../environments/environment';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [MainNavComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit {
  public showHeaderAndFooter: boolean = true;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const sessionJson = localStorage.getItem('session');

        if (sessionJson) {
          localStorage.clear();

          const session = JSON.parse(sessionJson);
          if (session) {
            this.http
              .patch(environment.apiUrl + '/auth/sessions', {
                refreshToken: session.refreshToken
              })
              .toPromise()
              .then((session: any) => {
                window.location.href =
                  'https://app.moffi.io?sid=' + session.id + '&token=' + session.accessToken + '&saveSession=true';
              });
          }
        }
      } catch (e) {
        // Nothing to do
      }

      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }

            return route;
          })
        )
        .subscribe((route) => {
          if (route.snapshot.data['page'] === 'contact_premium') {
            this.showHeaderAndFooter = false;
          }
        });
    }
  }
}
