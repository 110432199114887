import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MoffiUiKitModule } from '@moffi/moffi-ui-kit';
import { SubMenuItemComponent } from './sub-menu-item/sub-menu-item.component';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  imports: [NgIf, NgClass, MatMenuTrigger, MatIcon, MoffiUiKitModule, MatMenu, NgFor, MatButton]
})
export class MenuItemComponent {
  // Libellé
  @Input() title!: string;

  // Icone
  @Input() icon!: string;
  // Icone Hover
  @Input() iconHover!: string;

  // Description
  @Input() description!: string;

  // Liste des sous-menu enfants
  @ContentChildren(SubMenuItemComponent) submenuItems!: QueryList<SubMenuItemComponent>;

  // Event onClick
  @Output() eventClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMenuOpen: EventEmitter<MenuItemComponent> = new EventEmitter<MenuItemComponent>();

  // (sous-menu) MatMenu
  @ViewChild(MatMenuTrigger) menuTrigger?: MatMenuTrigger;

  private cdkOverlayContainer?: HTMLElement;

  currentImgButton!: string;
  isMenuOpen: boolean = false;
  canClose: boolean = true;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.icon) {
      this.currentImgButton = this.icon;
    }
    this.cdkOverlayContainer = this.el.nativeElement.closest('.cdk-overlay-container');
  }

  public isTriggerMenu(): boolean {
    if (this.description || (this.submenuItems != null && this.submenuItems.length != 0)) {
      return true;
    }
    return false;
  }

  onClick() {
    this.eventClick.emit();
  }

  onHoverImgButton(isHover: boolean) {
    if (isHover && this.iconHover) {
      this.currentImgButton = this.iconHover;
    } else {
      this.currentImgButton = this.icon;
    }
  }

  openMenuOnHover(): void {
    if (this.menuTrigger && !this.isMenuOpen) {
      this.isMenuOpen = true;
      this.onMenuOpen.emit(this);
      this.menuTrigger.openMenu();
      document.body.classList.add('menu-open');
    }
  }

  closeMenu() {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
      document.body.classList.remove('menu-open');
    }
  }

  menuClosed(): void {
    this.isMenuOpen = false;
    this.canClose = true;
  }

  onHoverButtonMenu() {
    this.isMenuOpen = true;
    this.onMenuOpen.emit(this);
  }

  setCanClose(canClose: boolean) {
    this.canClose = canClose;
  }
}
