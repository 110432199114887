import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, isPlatformBrowser, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MoffiUiKitModule } from '@moffi/moffi-ui-kit';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { MenuItemComponent } from '../../tools/menu/menu-item/menu-item.component';
import { SubMenuItemComponent } from '../../tools/menu/menu-item/sub-menu-item/sub-menu-item.component';

@Component({
  selector: 'app-main-nav-content',
  templateUrl: './main-nav-content.component.html',
  styleUrls: ['./main-nav-content.component.scss'],
  imports: [NgIf, MenuItemComponent, SubMenuItemComponent, MoffiUiKitModule, AsyncPipe, TranslatePipe]
})
export class MainNavContentComponent {
  // Liste des menus item
  @ViewChildren(MenuItemComponent) menuitems?: QueryList<MenuItemComponent>;

  isHandset$: Observable<boolean>;

  // Détection du clic en dehors du menu
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      // Clic en dehors de la div
      this.closeAllMenu();
    }
  }
  // Détection de la souris en dehors du site
  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: Event): void {
    // Méthode à appeler lorsque la souris quitte la zone active de la page
    this.closeAllMenu();
  }

  currentLanguage!: string;
  currentLanguageShort!: string;

  constructor(
    private router: Router,
    private el: ElementRef,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private navigationService: NavigationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isHandset$ = this.breakpointObserver.observe('(max-width: 1150px)').pipe(
      map((result) => result.matches),
      shareReplay()
    );

    // Permet de scroll en haut de la page quand on change de route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          const matsidenav = document.querySelector('.mat-sidenav-content');
          if (matsidenav) {
            matsidenav.scrollTop = 0;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.updateCurrentLanguage();
    });
  }

  callOpenAxeptioCookies() {
    (window as any).openAxeptioCookies();
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('userLang', language);
  }

  private updateCurrentLanguage() {
    this.currentLanguage = this.translate.currentLang;
    const languageShort = this.currentLanguage.substring(this.currentLanguage.length - 2, this.currentLanguage.length);
    this.currentLanguageShort = languageShort == 'GB' ? 'EN' : languageShort;
  }

  closeAllOtherMenu(menuItem: MenuItemComponent) {
    this.menuitems?.forEach((item) => {
      if (item !== menuItem) {
        item.closeMenu();
      }
    });
  }

  closeAllMenu() {
    this.menuitems?.forEach((item) => {
      if (item.canClose) {
        item.closeMenu();
      }
    });
  }

  redirectTo(path: string) {
    this.navigationService.navigateTo(path);
  }

  openLogin() {
    let url = 'https://app.moffi.io/';
    window.open(url, '_self');
  }
}
