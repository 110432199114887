<!-- Barre de navigation -->
<div class="toolbar-content">
  <!-- Logo -->
  <img
    [style.margin-left]="!(isHandset$ | async) ? '0%' : ''"
    class="toolbar-logo"
    src="assets/images/logo_moffi.svg"
    alt="Moffi logo"
    (mouseover)="closeAllMenu()"
    (click)="redirectTo('home')"
  />

  <!-- Menu (affiché uniquement sur grand écran)-->
  <div class="toolbar-menu" *ngIf="!(isHandset$ | async)">
    <!-- Solutions -->
    <app-menu-item
      title="{{ 'header.menu.solutions.title' | translate }}"
      description="{{ 'header.menu.solutions.description' | translate }}"
      (onMenuOpen)="closeAllOtherMenu($event)"
    >
      <!-- Flex Office -->
      <app-sub-menu-item
        (eventClick)="redirectTo('flex')"
        title="{{ 'header.menu.solutions.flexOffice.title' | translate }}"
        description="{{ 'header.menu.solutions.flexOffice.description' | translate }}"
      ></app-sub-menu-item>
      <!-- Télétravail -->
      <app-sub-menu-item
        (eventClick)="redirectTo('remote')"
        title="{{ 'header.menu.solutions.homeOffice.title' | translate }}"
        description="{{ 'header.menu.solutions.homeOffice.description' | translate }}"
      ></app-sub-menu-item>
      <!-- Smart Office -->
      <app-sub-menu-item
        (eventClick)="redirectTo('smart')"
        title="{{ 'header.menu.solutions.smartOffice.title' | translate }}"
        description="{{ 'header.menu.solutions.smartOffice.description' | translate }}"
      ></app-sub-menu-item>
      <!-- Parking -->
      <app-sub-menu-item
        (eventClick)="redirectTo('parking')"
        title="{{ 'header.menu.solutions.parking.title' | translate }}"
        description="{{ 'header.menu.solutions.parking.description' | translate }}"
      ></app-sub-menu-item>
    </app-menu-item>

    <!-- Intégrations -->
    <app-menu-item
      title="{{ 'header.menu.integrations.title' | translate }}"
      description="{{ 'header.menu.integrations.description' | translate }}"
      (onMenuOpen)="closeAllOtherMenu($event)"
    >
      <!-- Waldo -->
      <app-sub-menu-item
        (eventClick)="redirectTo('waldo')"
        title="{{ 'header.menu.integrations.teams.title' | translate }}"
        description="{{ 'header.menu.integrations.teams.description' | translate }}"
      ></app-sub-menu-item>
      <!-- Google -->
      <app-sub-menu-item
        (eventClick)="redirectTo('google')"
        title="{{ 'header.menu.integrations.google.title' | translate }}"
        description="{{ 'header.menu.integrations.google.description' | translate }}"
      ></app-sub-menu-item>
    </app-menu-item>

    <!-- Marketplace -->
    <app-menu-item
      title="{{ 'header.menu.marketplace.title' | translate }}"
      (eventClick)="redirectTo('marketplace')"
      (onMenuOpen)="closeAllOtherMenu($event)"
    ></app-menu-item>

    <!-- Offres -->
    <app-menu-item
      title="{{ 'header.menu.offers.title' | translate }}"
      (eventClick)="redirectTo('pricing')"
      (onMenuOpen)="closeAllOtherMenu($event)"
    ></app-menu-item>
  </div>

  <!-- Buttons -->
  <div class="toolbar-buttons" *ngIf="!(isHandset$ | async)">
    <!-- Langues -->
    <app-menu-item title="{{ currentLanguageShort }}" (onMenuOpen)="closeAllOtherMenu($event)">
      <app-sub-menu-item
        icon="assets/images/icon/flag_fr.svg"
        description="{{ 'languages.frFR.longName' | translate }}"
        (eventClick)="changeLanguage('fr-FR')"
      ></app-sub-menu-item>
      <app-sub-menu-item
        icon="assets/images/icon/flag_ca.svg"
        description="{{ 'languages.frCA.longName' | translate }}"
        (eventClick)="changeLanguage('fr-CA')"
      ></app-sub-menu-item>
      <app-sub-menu-item
        icon="assets/images/icon/flag_en.svg"
        description="{{ 'languages.enGB.longName' | translate }}"
        (eventClick)="changeLanguage('en-GB')"
      ></app-sub-menu-item>
    </app-menu-item>

    <!-- Aides -->
    <!-- <app-menu-item icon="assets/images/icon/icon_help.svg" 
                    iconHover="assets/images/icon/icon_help_selected.svg"
                    (onMenuOpen)="closeAllOtherMenu($event)">
            <app-sub-menu-item (eventClick)="callOpenAxeptioCookies()" description="{{'help.cookies.text' | translate}}"></app-sub-menu-item>
        </app-menu-item> -->

    <!-- Bouton Connexion -->
    <moffi-ui-button
      buttonType="simple"
      (eventClick)="openLogin()"
      label="{{ 'header.actions.goToApp.text' | translate }}"
      (mouseover)="closeAllMenu()"
    ></moffi-ui-button>

    <!-- Bouton Réserver démo -->
    <moffi-ui-button
      buttonType="light"
      (eventClick)="redirectTo('contact')"
      label="{{ 'header.actions.demo.text' | translate }}"
      (mouseover)="closeAllMenu()"
    ></moffi-ui-button>
  </div>
</div>
