import { Routes } from '@angular/router';
import { routesEnGb } from './routes/routes.en-gb';
import { routesFrCa } from './routes/routes.fr-ca';
import { routesFrFr } from './routes/routes.fr-fr';

export const routes: Routes = [
  { path: '', loadComponent: () => import('./page/landings/home/home.component').then((m) => m.HomeComponent) },
  ...routesFrFr,
  ...routesFrCa,
  ...routesEnGb,
  { path: '**', redirectTo: '/fr', pathMatch: 'full' } // Redirection par défaut
];
