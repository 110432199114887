<!-- Bouton avec sous-menu -->
<div class="menu-item" *ngIf="isTriggerMenu()">
  <!-- Libellé du bouton -->
  <button
    [ngClass]="isMenuOpen ? 'menu-item-button-selected' : 'menu-item-button'"
    class="fw-400 fs-16 primary-color"
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    (mouseenter)="openMenuOnHover()"
  >
    <div class="menu-title">
      {{ title }}
      <mat-icon
        [ngClass]="isMenuOpen ? 'menu-item-arrow-selected' : 'menu-item-arrow'"
        fontIcon="expand_more"
      ></mat-icon>
    </div>
  </button>

  <!-- Icône du bouton (à la place du libellé)-->
  <button
    class="fw-400 fs-16 primary-color menu-item-button"
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    *ngIf="icon != null"
    (mouseenter)="openMenuOnHover()"
  >
    <moffi-ui-button borderRadius="50%" label="?"></moffi-ui-button>
  </button>

  <!-- Sous menu-->
  <mat-menu #menu="matMenu" yPosition="below" (closed)="menuClosed()">
    <div class="menu-item-panel" (mouseenter)="setCanClose(false)" (mouseleave)="closeMenu()">
      <div class="fw-400 fs-14 primary-color menu-item-panel-desc" *ngIf="description != null">
        {{ description }}
      </div>
      <div class="menu-item-panel-subitems">
        <div class="menu-item-panel-subitem" *ngFor="let submenuItem of submenuItems" (click)="submenuItem.onClick()">
          <img
            alt="Icon submenu"
            style="margin-right: 0.5rem"
            src="{{ submenuItem.icon }}"
            *ngIf="submenuItem.icon != null"
          />
          <div>
            <div class="fw-700 fs-14 primary-color submenu-title">
              {{ submenuItem.title }}
            </div>
            <div class="fw-400 fs-14 primary-color">
              {{ submenuItem.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>

<!-- Bouton sans sous-menu-->
<div
  class="menu-item"
  *ngIf="!isTriggerMenu()"
  (click)="onClick()"
  (mouseenter)="onHoverButtonMenu()"
  (mouseleave)="menuClosed()"
>
  <!-- Libellé du bouton -->
  <button
    [ngClass]="isMenuOpen ? 'menu-item-button-selected' : 'menu-item-button'"
    class="fw-400 fs-16 primary-color"
    *ngIf="title != null"
  >
    <span class="menu-title">
      {{ title }}
    </span>
  </button>
  <!-- Icône du bouton (à la place du libellé)-->
  <button class="menu-item-button" mat-button *ngIf="icon != null">
    <img alt="Icon" src="{{ icon }}" />
  </button>
</div>
