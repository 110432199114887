<!-- Header -->
<app-main-nav [showHeaderAndFooter]="showHeaderAndFooter">
  <!-- Main Page -->
  <div class="main-content">
    <!-- Background -->
    <div class="home-background">
      <!-- Gradient Header Left-->
      <div style="width: 50rem; height: 50rem; top: 0%; left: 0%" class="gradient-circle purple-gradient"></div>
      <div style="top: 0%; left: 0%" class="gradient-circle pink-gradient"></div>
      <div style="top: -0.5%; left: -5%" class="gradient-circle green-gradient"></div>
      <div style="top: -1%; left: -10%" class="gradient-circle yellow-gradient"></div>
      <div style="top: 8%; left: -20%" class="gradient-circle green-gradient"></div>

      <!-- Gradient Header Left-->
      <div style="top: -1%; left: 92%" class="gradient-circle yellow-gradient"></div>
      <div style="top: 3%; left: 88%" class="gradient-circle green-gradient"></div>
      <div style="top: 5%; left: 80%" class="gradient-circle purple-gradient"></div>
    </div>

    <router-outlet></router-outlet>
  </div>

  @if (showHeaderAndFooter) {
    <!-- Footer -->
    <app-footer></app-footer>
  }
</app-main-nav>
