import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MoffiUiKitModule } from '@moffi/moffi-ui-kit';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SnackbarComponent } from '../../../component/tools/snackbar/snackbar.component';
import { ApiService } from '../../../service/api/api.service';
import { NavigationService } from '../../../service/navigation/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [NgClass, MoffiUiKitModule, FormsModule, ReactiveFormsModule, AsyncPipe, TranslatePipe]
})
export class FooterComponent {
  emailCtrl: FormControl = new FormControl('', [Validators.email]);
  errorKeys = {};

  currentLanguage: string;
  private languageChangeSubscription!: Subscription;

  isHandset$: Observable<boolean>;

  constructor(
    private navigationService: NavigationService,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    private apiService: ApiService,
    private _snackBar: MatSnackBar
  ) {
    this.currentLanguage = translateService.currentLang;
    this.isHandset$ = this.breakpointObserver.observe('(max-width: 1150px)').pipe(
      map((result) => result.matches),
      shareReplay()
    );
  }

  ngOnInit() {
    this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      this.currentLanguage = this.translateService.currentLang;
      // Erreurs
      this.errorKeys = {
        email: this.getTranslation('footer.newsletter.error.format'),
        required: this.getTranslation('footer.newsletter.error.required'),
        mailAlreadyUsed: this.getTranslation('footer.newsletter.error.mail_used')
      };
    });

    Promise.all([
      this.getTranslation('footer.newsletter.error.format'),
      this.getTranslation('footer.newsletter.error.required'),
      this.getTranslation('footer.newsletter.error.mail_used')
    ])
      .then((translations) => {
        this.errorKeys = {
          email: translations[0],
          required: translations[1],
          mailAlreadyUsed: translations[2]
        };
      })
      .catch((error) => {
        console.error('Error fetching translations:', error);
      });
  }

  getTranslation(key: string): string {
    let translation: string = '';
    this.translateService.get(key).subscribe((value: string) => {
      translation = value;
    });
    return translation;
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }

  sendMail() {
    if (this.emailCtrl.value.length > 0 && this.emailCtrl.valid) {
      // Si tous les champs sont valides, on appelle l'api
      this.apiService.subscribeNewsLetter(this.emailCtrl.value).subscribe({
        next: (result) => {
          this.emailCtrl.reset();
          this.openSnackBar();
        },
        error: (e) => {
          this.emailCtrl.setErrors({ mailAlreadyUsed: true });
        },
        complete: () => console.info('complete')
      });
    }
  }

  getActualYear(): string {
    return new Date().getFullYear().toString();
  }

  redirectTo(path: string) {
    this.navigationService.navigateTo(path);
  }

  getRoute(path: string) {
    return this.navigationService.getRoute(path);
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar'],
      data: {
        title: this.getTranslation('footer.newsletter.success.title'),
        message: this.getTranslation('footer.newsletter.success.text')
      }
    });
  }

  getLangShortText(lang: string) {
    switch (lang) {
      case 'fr-FR':
        return 'fr';
      case 'fr-CA':
        return 'fr-ca';
      case 'en-GB':
        return 'en';
    }
    return 'en';
  }
}
