import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { SubMenuItemComponent } from '../menu-item/sub-menu-item/sub-menu-item.component';

@Component({
  selector: 'app-tree-menu-item',
  templateUrl: './tree-menu-item.component.html',
  styleUrls: ['./tree-menu-item.component.scss'],
  imports: [NgClass, NgIf, MatIcon, NgFor]
})
export class TreeMenuItemComponent {
  // Libellé
  @Input() title!: string;

  // Libellé
  @Input() description!: string;

  // Font style
  @Input() fontStyleTitle!: string;

  // Font style desc
  @Input() fontStyleDesc!: string;

  // Liste des sous-menu enfants
  @ContentChildren(SubMenuItemComponent) submenuItems!: QueryList<SubMenuItemComponent>;

  @Output() onMenuOpen: EventEmitter<TreeMenuItemComponent> = new EventEmitter<TreeMenuItemComponent>();

  private open: boolean = false;

  ngOnInit() {
    if (this.fontStyleTitle == null) {
      this.fontStyleTitle = 'fw-500 fs-20 dark-color';
    }
    if (this.fontStyleDesc == null) {
      this.fontStyleDesc = 'fw-400 fs-14 dark-color';
    }
  }

  isOpen() {
    return this.open;
  }

  toggleSubMenu() {
    if (this.hasChildren()) {
      this.open = !this.open;
      // En cas de fermeture du menu, on envoie un event
      if (this.open) {
        this.onMenuOpen.emit(this);
      }
    }
  }

  hasChildren() {
    return this.submenuItems.length != 0;
  }
}
