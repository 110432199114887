<div [ngClass]="!(isHandset$ | async) ? 'footer' : 'footer-mobile'">
  <!-- Background -->
  <div class="footer-background">
    <!-- Gradient Footer Left-->
    <div style="width: 50rem; height: 50rem; top: 18%; left: -10%" class="gradient-circle purple-gradient"></div>
    <div style="top: -33%; left: -6%" class="gradient-circle green-gradient"></div>

    <!-- Gradient Footer Left-->
    <div style="top: -27%; left: 87%" class="gradient-circle cyan-gradient"></div>
    <div style="top: -43%; left: 71%" class="gradient-circle pink-gradient"></div>
  </div>

  <div [ngClass]="!(isHandset$ | async) ? 'footer-content' : 'footer-content-mobile'">
    <!--------------- Panel Info ------------------->
    <div class="footer-info" [ngClass]="!(isHandset$ | async) ? '' : 'panel-mobile'">
      <!-- Logo Moffi-->
      <img src="assets/images/logo_moffi_white.svg" alt="Moffi logo" />
      <!-- Description -->
      <div class="fw-400 fs-16 dark-color">
        {{ 'footer.app.design' | translate }}
        <br />
        {{ 'footer.app.location' | translate }}
      </div>
      <!-- Form mail -->
      <div class="form-email">
        <div style="margin-bottom: 0.5rem" class="fw-400 fs-14 dark-color">
          {{ 'footer.newsletter.description' | translate }}
        </div>
        <moffi-ui-textbox
          id="email"
          name="email"
          [formControl]="emailCtrl"
          type="email"
          [width]="!(isHandset$ | async) ? '22rem' : '100%'"
          [darkMode]="true"
          [withButton]="true"
          [errorIcon]="true"
          buttonLabel="{{ 'footer.newsletter.button' | translate }}"
          [placeholder]="'footer.newsletter.placeholder' | translate"
          [customErrorMessages]="errorKeys"
          label="{{ 'footer.newsletter.label' | translate }}"
          [required]="true"
          [enabled]="true"
          (onClickButton)="sendMail()"
          ngDefaultControl
        >
        </moffi-ui-textbox>
      </div>
      <!-- Réseaux sociaux -->
      <div class="footer-panel">
        <a class="a-block" href="https://www.linkedin.com/company/moffi" target="_blank" rel="noopener">
          <img class="footer-link" src="assets/images/footer/logo_linkedIn.svg" alt="LinkedIn" />
        </a>
        <a class="a-block" href="https://www.youtube.com/@moffi_io" target="_blank" rel="noopener">
          <img
            class="footer-link"
            style="margin-top: 0.3rem"
            src="assets/images/footer/logo_youtube.svg"
            alt="Youtube"
          />
        </a>
      </div>
      <!-- Téléchargements -->
      <div class="footer-panel">
        <a
          class="a-block"
          href="https://apps.apple.com/fr/app/moffi/id1528941537?platform=iphone"
          target="_blank"
          rel="noopener"
        >
          <img class="footer-link" src="assets/images/footer/{{ currentLanguage }}/app_store.svg" alt="Apple Store" />
        </a>
        <a
          class="a-block"
          href="https://play.google.com/store/apps/details?id=io.moffi.moffi_next&pli=1"
          target="_blank"
          rel="noopener"
        >
          <img class="footer-link" src="assets/images/footer/{{ currentLanguage }}/google_play.svg" alt="Google Play" />
        </a>
      </div>

      <!-- Membres actifs -->
      <div class="footer-panel">
        <div class="fw-400 fs-16 dark-color">
          {{ 'footer.member.description' | translate }}
        </div>
        <img src="assets/images/footer/idet.svg" alt="Idet" />
        <img src="assets/images/footer/le_lab_rh.svg" alt="Le LAB RH" />
      </div>
    </div>

    <!--------------- Panel Content ------------------->
    <div class="footer-nav" [ngClass]="!(isHandset$ | async) ? '' : 'panel-mobile'">
      <!-- Navigation -->
      <div class="footer-nav-content">
        <!-- Solution -->
        <div class="footer-nav-subitems">
          <div class="fw-500 fs-20 dark-color">
            {{ 'footer.solutions.title' | translate }}
          </div>
          <div class="footer-nav-subitems fw-400 fs-14 dark-color">
            <a [href]="getRoute('flex')">
              {{ 'footer.solutions.flexOffice' | translate }}
            </a>
            <a [href]="getRoute('remote')">
              {{ 'footer.solutions.remote' | translate }}
            </a>
            <a [href]="getRoute('smart')">
              {{ 'footer.solutions.smartOffice' | translate }}
            </a>
            <a [href]="getRoute('parking')">
              {{ 'footer.solutions.parking' | translate }}
            </a>
          </div>
        </div>
        <!-- Intégrations -->
        <div class="footer-nav-subitems">
          <div class="fw-500 fs-20 dark-color">
            {{ 'footer.integrations.title' | translate }}
          </div>
          <div class="footer-nav-subitems fw-400 fs-14 dark-color">
            <a [href]="getRoute('waldo')">
              {{ 'footer.integrations.teams' | translate }}
            </a>
            <a [href]="getRoute('google')">
              {{ 'footer.integrations.google' | translate }}
            </a>
            <a [href]="getRoute('marketplace')">
              {{ 'footer.integrations.marketplace' | translate }}
            </a>
          </div>
        </div>
        <!-- A propos -->
        <div class="footer-nav-subitems">
          <div class="fw-500 fs-20 dark-color">
            {{ 'footer.about.title' | translate }}
          </div>
          <div class="footer-nav-subitems fw-400 fs-14 dark-color">
            <a [href]="getRoute('pricing')">
              {{ 'footer.about.offers' | translate }}
            </a>
            <a href="https://blog.moffi.io/" target="_blank">
              {{ 'footer.about.blog' | translate }}
            </a>
            <a href="https://status.moffi.io/" target="_blank">
              {{ 'footer.about.status' | translate }}
            </a>
            <a [href]="getRoute('glossary')">
              {{ 'footer.about.glossary' | translate }}
            </a>
          </div>
        </div>
        <!-- Contact -->
        <div class="footer-nav-subitems">
          <div class="fw-500 fs-20 dark-color">
            {{ 'footer.contact.title' | translate }}
          </div>
          <div class="footer-nav-subitems fw-400 fs-14 dark-color">
            <a [href]="getRoute('idea')">
              {{ 'footer.contact.idea' | translate }}
            </a>
            <a href="https://moffi.typeform.com/to/Wa4Nvav1?typeform-source=moffi.io" target="_blank">
              {{ 'footer.contact.partnership' | translate }}
            </a>
            <a [href]="getRoute('join_team')">
              {{ 'footer.contact.apply' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="footer-copyright" [ngClass]="!(isHandset$ | async) ? '' : 'panel-mobile'">
      <div class="fw-400 fs-14 dark-color">
        <span>{{ 'footer.copyright.description1' | translate }}</span>
        <span> {{ getActualYear() }} </span>
        <span>{{ 'footer.copyright.description2' | translate }}</span>
      </div>
    </div>

    <!-- Infos légales -->
    <div class="footer-legal" [ngClass]="!(isHandset$ | async) ? '' : 'panel-mobile'">
      <!-- CGU-->
      <a [href]="getRoute('terms_of_use')" class="fw-400 fs-14 dark-color"> {{ 'footer.legal.gcu' | translate }}</a>
      <!-- Confidentialité -->
      <a [href]="getRoute('privacy_policy')" class="fw-400 fs-14 dark-color">
        {{ 'footer.legal.privacy' | translate }}</a
      >
      <!-- Cookies -->
      <a href="javascript:openAxeptioCookies()" class="fw-400 fs-14 dark-color">
        {{ 'footer.legal.cookies' | translate }}</a
      >
    </div>
  </div>
</div>
